/* React Select -- Inline Modifier
* Inline container with margin for better inline spacing between other elements 
*/
.body {
  height: auto;

  --CountryFlagIcon-height: 1em
}

.object-key-val {
  overflow: scroll;
}

.react-select--inline {
    display: inline-block;
    margin: 0 0.25em;
  }
  
  /* Remove border, outline, box-shadow, and min-height values */
  .react-select--inline .react-select__control {
    border: none;
    outline: none;
    box-shadow: none;
    min-height: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
  
  /* Tighten up spacing */
  .react-select--inline .react-select__value-container {
    padding: 0;
  }

  /* Position value relative (instead of absolute) and remove transform and max-width */
  .react-select--inline .react-select__single-value {
    font-weight: bold;
    position: relative;
    transform: none;
    max-width: none;
    color: white;
  }
  
  /* CSS triangle dropdown indicator after the selected value */
  .react-select--inline .react-select__single-value::after {
    content: " ";
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    margin-left: 0.25em;
    top: -0.125em;
  }

  :global .react-codemirror2 {
  /* Set height, width, borders, and global font properties here */
  height: '100%';
}
:global .CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: '100%';
  color: red;
  direction: ltr;
}

.CodeMirror {
  height: auto !important
}

.code-mirror {
  height: auto !important;
}

.removePadding {
  padding: 0;
}