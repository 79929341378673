.cord-sidebar-custom {
    /* See https://docs.cord.com/reference/css-variables#floating-sidebar-launcher */

    --cord-sidebar-width: 330px;

    /* Background */
    --cord-color-base:#222b36;
    --cord-color-base-strong: #303d4c ;
    
    --cord-sidebar-background-color: #222b36;
    --cord-sidebar-header-background-color: #222b36;
    
    /* Text color */
    --cord-color-content-primary: white; 
    --cord-color-brand-primary: white;

    /* Primary Button */

    --cord-primary-button-background-color--disabled: grey;
    --cord-primary-button-background-color--enabled: white;

    --cord-primary-button-background-color: black;
    --cord-primary-button-background-color--hover: rgb(32, 32, 32);

    --cord-primary-button-content-color: white;
    --cord-primary-button-content-color--hover: white;	



    /* SEnd button */
    --cord-thread-send-button-text-color: black;
    --cord-thread-send-button-text-color--hover: white;
    --cord-thread-send-button-background-color: white;


    /* Headers */
    --cord-color-content-emphasis:white;

    /* Secondary */
    --cord-secondary-button-background-color: white;
    --cord-secondary-button-background-color--hover: rgb(215, 215, 215);
    --cord-secondary-button-content-color: black;
    --cord-secondary-button-content-color--hover: black;


    /* Tooltip */
    --cord-tooltip-background-color: black;
    --cord-tooltip-content-color: white;

    /* Avatar */
    --cord-avatar-background-color: white;

    --cord-launcher-background-color: white;
    --cord-launcher-content-color:#222b36
}
